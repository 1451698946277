import React, { memo } from 'react';
import SearchInput from 'components/SearchInput';
import { t } from 'i18next';

export default memo(function ShowroomTopFilter() {
  return (
    <div className='flex items-center gap-2'>
      <SearchInput
        inputKey='rooms'
        label={t('common.filter.roomsNumber')}
        type='number'
        InputProps={{ endAdornment: null }}
      />
    </div>
  );
});
