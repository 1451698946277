import { Button, Drawer } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import DateTypeFilter from './items/dateTypeFilter';
import useMultipleQueries from 'hooks/useMultipleQueries';
import useSearch from 'hooks/useSearch';
import StaffFilter from '../ui/filters/items/StaffFilter';
import CrmColumnsFilter from './items/crmColumnsFilter';
import GenericRadiosFilter from './items/GenericRadiosFilter';
import BujutRangeFilter from './items/BujutRangeFilter';
import RegionFilter from './items/RegionFilter';
import CrmFieldsFilter from './CrmFieldsFilter';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useQuery } from 'react-query';
import { useState } from 'react';
import GenericSelectFilter from '../ui/filters/items/GenericSelectFilter';

const leadTypeOptions = [
  {
    code: 'DELETED',
    label: `O'chirilgan lidlar`,
  },
  {
    code: 'ALL',
    label: 'Barcha lidlar',
  },
];

const TaskFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const query = useSearch();
  const axiosPrivate = useAxiosPrivate();
  const setMultipleQueries = useMultipleQueries();
  const [searchParams, setSearchparams] = useSearchParams();

  const [hasError, setHasError] = useState(false);

  const handleResetFiler = () => {
    const fields = Object.keys(Object.fromEntries([...searchParams]))
      ?.filter((key) => key?.startsWith('fields['))
      ?.map((f) => String(f));
    fields?.forEach((f) => searchParams.delete(f));
    setSearchparams(searchParams);
    setMultipleQueries({
      from: '',
      till: '',
      task_from: '',
      task_till: '',
      pipeStatusId: '',
      lead_status: '',
      created_by: '',
      user_id: '',
      butjet_from: '',
      butjet_till: '',
      region: '',
      reject_id: '',
      staff: '',
      order: '',
      order_field: '',
    });
  };

  const {
    isLoading: rejectLoading,
    isFetching,
    data,
  } = useQuery({
    queryKey: ['/dictionary/rejectreasons'],
    queryFn: async function () {
      const response = await axiosPrivate.get(`/dictionary/rejectreasons`);
      return response.data.data;
    },
    onSuccess: (res) => {
      const data = res?.lead;
      if (data) {
      }
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end'>
          <Button
            color='error'
            variant='outlined'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            <i className='bi bi-arrow-repeat text-lg mr-1' />
            Tozalash
          </Button>
        </div>
        <DateTypeFilter disabled={isLoading} />
        <CrmColumnsFilter disabled={isLoading} />
        <DateTypeFilter
          disabled={isLoading}
          queries={['task_from', 'task_till']}
          label='Topshiriq: '
        />
        <GenericRadiosFilter
          disabled={isLoading}
          options={leadTypeOptions}
          queryName='lead_status'
          removeAll
          label='Lidlar holati'
          defaultValue={{ label: 'Faqat aktiv lidlar', code: 'all' }}
        />
        <BujutRangeFilter
          block={query.get('crm_block')}
          className='!min-h-[34px] !h-[34px] !my-[5px !text-[12px]'
        />
        <RegionFilter
          label='Hudud'
          block={query.get('crm_block')}
          className='!min-h-[34px] !h-[34px] !my-[5px !text-[12px]'
        />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.staff'
          query='user_id'
          className='!min-h-[34px] !h-[34px] !my-[5px !text-[12px]'
        />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.authorStaff'
          query='created_by'
          className='!min-h-[34px] !h-[34px] !my-[5px !text-[12px]'
        />
        <GenericSelectFilter
          options={data || []}
          disabled={hasError || rejectLoading || isFetching}
          query={'reject_id'}
          label={"Bekor bo'lish sababi"}
          optionProp={{ label: 'name', value: 'id' }}
          className='!min-h-[34px] !h-[34px] !my-[5px] !text-[12px]'
          marginContainer='0'
        />
        <CrmFieldsFilter />
      </div>
    </Drawer>
  );
};
export default TaskFilterDrawer;
