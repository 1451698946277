import { Button } from '@mui/material';
import CrmFilterDrawer from 'components/dashboard/CrmFilterDrawer';
import StaffFilter from 'components/ui/filters/items/StaffFilter';
import { memo, useEffect, useState } from 'react';
import CRMBlocksMenu from './blocks/CRMBlocksMenu';
import CRMActionsDropDown from './CRMActionsDropDown';
import CRMLeadAdd from './lead-actions/CRMLeadAdd';
import CRMLeadSearch from './lead-actions/CRMLeadSearch';
import useAuth from 'hooks/useAuth';
import useSearch from 'hooks/useSearch';
import useSetQuery from 'hooks/useSetQuery';

const CRMTopPanel = ({ addBlock = false, addLead = false }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const query = useSearch();
  const setQuery = useSetQuery();
  const [{ user }] = useAuth();

  useEffect(() => {
    if (
      !query.get('staff') &&
      user?.user?.id &&
      (user?.user?.role == 3 || user?.user?.role == 4)
    ) {
      setQuery('staff', user?.user?.id);
    }
  }, []);

  return (
    <div className='crm-top-panel-wrapper !w-[calc(100%+32px)] px-4 bg-[#F8F9FB] border-b border-b-[#E5E9EB] -mt-2 -ml-4 !-mr-4'>
      <CRMBlocksMenu addAllowed={addBlock} />
      <div className='ml-2'>
        <Button
          variant='contained'
          onClick={() => setOpenFilter(!openFilter)}
          color='info'
          className='h-10 !min-w-[40px] !w-[40px]'
        >
          {/* <span className="text-base font-normal">
						{t("common.button.filter")}
					</span> */}
          <i className='bi bi-filter text-xl' />
        </Button>
      </div>
      <div className='flex ml-24 items-center'>
        <div className='mr-3 2xl:w-[200px] w-[150px]'>
          <StaffFilter />
        </div>
        <div className='mr-3'>
          <CRMLeadSearch />
        </div>
        <div className='mr-3'>
          <CRMLeadAdd />
        </div>
        <div>
          <CRMActionsDropDown />
        </div>

        <CrmFilterDrawer open={openFilter} setOpen={setOpenFilter} />
      </div>
    </div>
  );
};
export default memo(CRMTopPanel);
